/* eslint-disable vue/no-unused-components */
<template>
    <v-app>
      <header-component/>
    
    <v-main>
				<router-view style="max-width:1161px;"></router-view>
		
        <login-dialog/>
        <complete-profile/>
        <apply-vaga-dialog/>
    </v-main>

    <loader/>
    
    <Footer/>
  
  </v-app>
</template>

<script>

import Loader from '../components/loader/Loader.vue'
import Footer from '../components/footer/newFooter.vue';
import loginDialog from '../components/candidate/dialogs/loginDialog.vue'
import completeProfile from '../components/candidate/dialogs/completeProfile2.vue'
import applyVagaDialog from '../components/candidate/dialogs/applyVagaDialog.vue'
import HeaderComponent from '../components/header/MainNavSite.vue'
import api from '../services/index.js';
export default {
  components: { Footer, HeaderComponent, Loader, loginDialog, completeProfile, applyVagaDialog},
  created() {
    this.getPublicity()
    setTimeout(this.active, 20000)
  },
  methods: {
    checkUserProfile(){
      if(this.candidate?.personal_data && this.candidate.personal_data == null){
        this.$store.dispatch('changeCompleteProfile', true)
      }
      else if(this.candidate?.anexos && this.candidate.anexos.length == 0) {
        this.$store.dispatch('changeCompleteProfile', true)
      }
      else if(this.candidate?.education && this.candidate.education.length == 0) {
        this.$store.dispatch('changeCompleteProfile', true)
      }
      else if(this.candidate?.language && this.candidate.language.length == 0) {
        this.$store.dispatch('changeCompleteProfile', true)
      }
    },
    active() {
      if(!this.$store.state.token){
        this.$store.dispatch('changeLoginDialog', true)
      } else{
        this.checkUserProfile()      
      }
    },
    getPublicity() {
       api.get(`/guest/publicity/1`)
       .then(res => {
        //  console.log(res.data.data)
         this.$store.dispatch('getPublicity', res.data.data)
       })
    }
  },
  computed: {
    candidate() {
            let candidate = this.$store.state.candidate
            return candidate
        },
    isMobile() {
			return this.$vuetify.breakpoint.smAndUp ? true : false;
		}
  },

}
</script>

<style>

</style>