const firebaseConfig = {
    apiKey: "AIzaSyCZ7vQkBopnKYpqTCudLIatbJV1rcT9P0k",
    authDomain: "guglamz.firebaseapp.com",
    databaseURL: "https://guglamz.firebaseio.com",
    projectId: "guglamz",
    storageBucket: "guglamz.appspot.com",
    messagingSenderId: "752126581328",
    appId: "1:752126581328:web:b280c09f9ed2d9e9388f55",
    measurementId: "G-4LZVP3HK18"
  };

  export default firebaseConfig;